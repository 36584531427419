<template>
  <view-base ref="base" class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-3 font-weight-bold" title-tag="h2">
            <b-img fluid src="https://eatz-s3.s3.eu-north-1.amazonaws.com/delivery/logo.png" alt="EatzDelivery" />
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{invalid}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('auth.email') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('auth.password') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="current-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button class="mt-3" type="submit" variant="primary" block :disabled="invalid">
                {{ $t('auth.signin') }}
              </b-button>
              <span v-if="error != ''" class="text-danger">{{ error }}</span>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </view-base>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import ViewBase from '@/views/ViewBase.vue'

import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    ViewBase,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      error: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.base.showLoading()
      this.$refs.loginForm.validate().then(success => {
        axios.defaults.withCredentials = true
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
          .then(response => {
            useJwt.setToken(response.data.access_token)
            window.$cookies.set('userData', JSON.stringify(response.data.userData))
            this.$refs.base.showToast(`${this.$t('Olá')} ${response.data.userData.name || response.data.userData.email}`, 'CoffeeIcon', 'success', this.$t('Login realizado com sucesso!'))
            this.$refs.base.hideLoading()
            this.$router.replace(getHomeRouteForLoggedInUser(response.data.userData.role))
          })
          .catch(error => {
            this.$refs.base.hideLoading()
            this.setError(error.response.data.message ?? 'Ocorreu problemas no seu request, tente novamente mais tarde')
          })
        }
      })
    },
    setError(msg) {
      this.error = this.$t(msg)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
